import React from "react";
import { Row, Col } from "reactstrap";

const Introduction = () => {
  let basicInfoVos = [
    {
      label: "Experience",
      details: "10 Years",
    },
    {
      label: "Age",
      details: "32 Years",
    },
    {
      label: "Email",
      details: "avinashrathod91@gmail.com",
    },
    {
      label: "Language",
      details: "English, Hindi, Marathi",
    },
    {
      label: "City",
      details: "Pune, Maharashtra, India",
    },
  ];

  return (
    <div id="intro-tab" className="section-container">
      <div className="section-header">
        <h2>About Me</h2>
      </div>
      <div className="container-fluid section">
        <Row>
          <Col md="6">
            <div className="sub-section">
              <div className="sub-section-header">
                <h3>Professional Summary</h3>
              </div>
              <p>
                I’m <b>Avinash Rathod</b>, a passionate and results-driven{" "}
                <b>
                  Front-End Developer with around 10 years of professional
                  experience
                </b>{" "}
                creating seamless, interactive, and scalable web applications. I
                specialize in crafting modern, user-centric interfaces using{" "}
                <b>React, TypeScript, and JavaScript, </b>along with a proven
                track record of designing and implementing reusable,
                maintainable component libraries.
              </p>
              <p>
                My technical expertise includes building scalable applications
                and contributing to the development of design systems, such as
                leveraging <b>Material-UI and Turborepo </b> for highly
                efficient reusable components. I’ve also demonstrated innovation
                by engineering custom solutions like a Table component using{" "}
                <b>TanStack React Table,</b> delivering significant cost savings
                and enhanced functionality.
              </p>
              <p>
                With 6+ years of deep expertise in React and nearly a decade of
                experience in JavaScript, I bring both seasoned skill and
                adaptability to diverse project needs. My earlier work with
                Angular (2015-2018) has further enriched my toolkit, giving me
                the versatility to work across various frameworks and
                technologies.
              </p>
              <p>
                I thrive on collaborating with teams to build scalable and
                performant web applications while maintaining a keen eye on
                UI/UX details. Whether it’s developing cutting-edge solutions or
                optimizing workflows, my goal is to bridge the gap between
                design and development with clean, maintainable code.
              </p>
            </div>
          </Col>

          <Col md="6">
            <div className="sub-section">
              <div className="sub-section-header">
                <h3>Basic information</h3>
              </div>
              <div className="personal-info">
                <ul>
                  {basicInfoVos.map((basicInfo) => {
                    return (
                      <li key={basicInfo.label}>
                        <span className="label">{basicInfo.label}</span>
                        <span className="details">{basicInfo.details}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Introduction;
