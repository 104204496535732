import React from "react";
import { Row, Col } from "reactstrap";

const Contact = () => (
  <div id="contact-tab" className="section-container">
    <div className="section-header">
      <h2>Contact</h2>
    </div>
    <div className="container-fluid section contact-section">
      <Row>
        <Col sm="6">
          <span className="contact-address-icon">
            <i className="fas fa-map-marked" />
          </span>
          <h5>Address</h5>
          <p>Pune, Maharashtra, India</p>
        </Col>
        <Col sm="6">
          <span className="contact-address-icon">
            <i className="fas fa-envelope" />
          </span>
          <h5>Email</h5>
          <p>avinashrathod91@gmail.com</p>
        </Col>
      </Row>
      <div className="text-right">
        <b>Version:</b> 1.0.6
      </div>
    </div>
  </div>
);

export default Contact;
