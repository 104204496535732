import React from "react";
import { NavLink } from "reactstrap";

const Home = () => (
  <div id="home-tab" className="container-fluid home-container">
    <div className="profile-introduction">
      <div className="text-center profile-image">
        <img
          src="/assets/images/avinash-rathod2.jpg"
          alt="Avinash Rathod"
          className="img-thumbnail"
        />
      </div>
      <h1>I'm Avinash Rathod</h1>
      <p>Passionate Software Developer</p>
    </div>

    <div className="social-media-link">
      <NavLink href="https://www.linkedin.com/in/avinash-rathod/">
        <i className="fab fa-linkedin-in" />
      </NavLink>
      <NavLink href="https://stackoverflow.com/users/6762352/avinash-rathod?tab=profile">
        <i className="fab fa-stack-overflow" />
      </NavLink>
      <NavLink href="https://www.instagram.com/it_oviyan">
        <i className="fab fa-instagram" />
      </NavLink>
    </div>

    <div className="btn-container">
      {/* <a href="#" className="btn btn-outline-primary btn-lg" role="button">
        Hire Me
      </a> */}
      <a
        href="/assets/resume/avinash-rathod-resume.pdf"
        target="_blank"
        className="btn btn-outline-primary btn-lg"
        role="button"
      >
        Download CV
      </a>
    </div>
  </div>
);

export default Home;
