import React, { Component } from "react";

import HeadBar from "./HeadBar";
import Introduction from "./Introduction";
import Skills from "./Skills";
import ProfessionalExperience from "./ProfessionalExperience";
import Home from "./Home";
import Services from "./Services";
import Contact from "./Contact";

class Layout extends Component {
  render() {
    return (
      <div className="layout-content">
        <HeadBar />
        <Home />
        <Introduction />
        <Skills />
        <ProfessionalExperience />
        <Services />
        <Contact />
      </div>
    );
  }
}

export default Layout;
