import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";

const Skills = () => {
  let jsSkillVos = [
    {
      name: "React",
      icon: "/assets/images/react.svg",
      // iconFontAwesome: "fa-brands fa-react",
      alt: "React Logo",
      skillValue: 85
    },
    {
      name: "TypeScript",
      icon: "/assets/images/typescript.svg",
      alt: "TypeScript Logo",
      skillValue: 80
    },
    {
      name: "JavaScript",
      icon: "/assets/images/javascript.svg",
      // iconFontAwesome: "fa-brands fa-js",
      alt: "JavaScript Logo",
      skillValue: 85
    },
    {
      name: "Redux",
      icon: "/assets/images/redux.svg",
      alt: "Redux Logo",
      skillValue: 80
    },
    {
      name: "GraphQL",
      icon: "/assets/images/apollo-graphql.svg",
      alt: "GraphQL Logo",
      skillValue: 80
    },
    {
      name: "Node.js",
      icon: "/assets/images/nodejs.svg",
      // iconFontAwesome: "fa-brands fa-node",
      alt: "Node.js Logo",
      skillValue: 70
    },
    {
      name: "React Testing Library",
      icon: "/assets/images/react-testing-library.png",
      alt: "React Testing Library Logo",
      skillValue: 75
    },
    {
      name: "Angular",
      icon: "/assets/images/angular.svg",
      // iconFontAwesome: "fa-brands fa-angular",
      alt: "Angular Logo",
      skillValue: 65
    }
  ];

  let designSkillVos = [
    {
      name: "HTML 5",
      icon: "/assets/images/html-5.svg",
      // iconFontAwesome: "fa-html5",
      alt: "HTML5 Logo",
      skillValue: 85
    },
    {
      name: "CSS",
      icon: "/assets/images/css-3.svg",
      // iconFontAwesome: "fab fa-css3-alt",
      alt: "CSS Logo",
      skillValue: 80
    },
    {
      name: "Material-UI",
      icon: "/assets/images/material-ui.svg",
      iconFontAwesome: "",
      alt: "Material-UI Logo",
      skillValue: 85
    },
    {
      name: "Bootstrap",
      icon: "/assets/images/bootstrap-5.svg",
      // iconFontAwesome: "fa-brands fa-bootstrap",
      alt: "Bootstrap Logo",
      skillValue: 80
    },
  ];

  return (
    <div id="skill-tab" className="section-container">
      <div className="section-header">
        <h2>Skills</h2>
      </div>

      <div className="container-fluid section">
        <Row>
          <Col md="6">
            <div className="sub-section">
              <div className="sub-section-header">
                <h3>{"Framework / library"}</h3>
              </div>

              {jsSkillVos.map((jsSkillVo, index) => {
                return (
                  <Fragment key={jsSkillVo.name}>
                    <div className="skill-item">
                      <Row>
                        <Col xs="2">
                          {jsSkillVo.iconFontAwesome ? (
                            <i className={"fab " + jsSkillVo.iconFontAwesome} />
                          ) : (
                            <img
                              src={jsSkillVo.icon}
                              alt={jsSkillVo.alt}
                              className="img-thumbnail"
                            />
                          )}
                        </Col>
                        <Col xs="10">
                          <p>
                            <label>{jsSkillVo.name}</label>
                            <span className="skill-value">
                              {jsSkillVo.skillValue}%
                            </span>
                          </p>
                          <div className="skill-bar-container">
                            <div
                              className={
                                "skill-bar percent-" + jsSkillVo.skillValue
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {jsSkillVos.length === index + 1 ? null : <hr />}
                  </Fragment>
                );
              })}
            </div>
          </Col>

          <Col md="6">
            <div className="sub-section">
              <div className="sub-section-header">
                <h3>Design Skills</h3>
              </div>
              {designSkillVos.map((designSkillVo, index) => {
                return (
                  <Fragment key={designSkillVo.name}>
                    <div className="skill-item">
                      <Row>
                        <Col xs="2">
                           {designSkillVo.iconFontAwesome ? (
                            <i className={"fab " + designSkillVo.iconFontAwesome} />
                          ) : (
                            <img
                              src={designSkillVo.icon}
                              alt={designSkillVo.alt}
                              className="img-thumbnail"
                            />
                          )}
                        </Col>
                        <Col xs="10">
                          <p>
                            <label>{designSkillVo.name}</label>
                            <span className="skill-value">
                              {designSkillVo.skillValue}%
                            </span>
                          </p>
                          <div className="skill-bar-container">
                            <div
                              className={
                                "skill-bar percent-" + designSkillVo.skillValue
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {designSkillVos.length === index + 1 ? null : <hr />}
                  </Fragment>
                );
              })}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Skills;
