import React from "react";

const ProfessionalExperience = () => (
  <div id="experience-tab" className="section-container">
    <div className="section-header">
      <h2>Professional Experience</h2>
    </div>
    <div className="container-fluid section experience-section">
      <div className="row">
        <div className="col-md-12">
          <div className="main-timeline">
            <div className="timeline">
              <div className="timeline-content">
                <span className="bullets" />
                <div className="title">
                  <h3>Associate Vice President</h3>
                  <h6 className="card-subtitle text-muted">
                    <div className="company-name">Deutsche Bank, Pune</div>
                    <div className="service-duration">Dec 2023 - Present</div>
                  </h6>
                </div>

                <div className="card description">
                  <div className="card-body">
                    <ul className="card-text">
                      <li>
                        <strong>Technologies Worked:</strong> React, TypeScript,
                        JavaScript, material-UI, Storybook, Turborepo, GCP,
                        HTML5, and CSS.
                      </li>
                      <li>
                        {
                          "Spearheaded the development of multiple web portals using React, TypeScript, and Material-UI, deployed on Google Cloud Platform, enhancing user experience and application performance."
                        }
                      </li>
                      <li>
                        {
                          "Architected and implemented a comprehensive design system and component library using Storybook, Material-UI, and Turbo Repo, streamlining development processes and ensuring consistency across teams."
                        }
                      </li>
                      <li>
                        {
                          "Engineered a custom Table component with TanStack React Table, replacing AG Grid and saving the company $999 per developer annually."
                        }
                      </li>
                      <li>{"Peer code review and support."}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="timeline">
              <div className="timeline-content">
                <span className="bullets" />
                <div className="title">
                  <h3>Lead Software Engineer</h3>
                  <h6 className="card-subtitle text-muted">
                    <div className="company-name">GS Lab, Pune</div>
                    <div className="service-duration">Oct 2018 - Dec 2023</div>
                  </h6>
                </div>

                <div className="card description">
                  <div className="card-body">
                    <ul className="card-text">
                      <li>
                        <strong>Technologies Worked:</strong> React, TypeScript,
                        JavaScript, GraphQL, Node.js, React Testing Library,
                        material-UI, HTML5, and CSS.
                      </li>
                      <li>
                        {
                          "Creating self-contained, reusable modules, and components."
                        }
                      </li>
                      <li>
                        {
                          "Creating unit and functional tests to validate code using React Testing Framework."
                        }
                      </li>
                      <li>
                        {
                          "Participating in strategy and brainstorming meetings."
                        }
                      </li>
                      <li>{"Peer code review and support."}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="timeline">
              <div className="timeline-content">
                <span className="bullets" />
                <div className="title">
                  <h3>Associate Consultant</h3>
                  <h6 className="card-subtitle text-muted">
                    <div className="company-name">Capgemini, Pune</div>
                    <div className="service-duration">
                      April 2018 - Oct 2018
                    </div>
                  </h6>
                </div>

                <div className="card description">
                  <div className="card-body">
                    <ul className="card-text">
                      <li>
                        <strong>Technologies Worked:</strong> Angular 4,
                        TypeScript, HTML5, and CSS.
                      </li>
                      <li>
                        {
                          "Creating self-contained, reusable modules, and components."
                        }
                      </li>
                      <li>
                        {
                          "Participating in strategy and brainstorming meetings."
                        }
                      </li>
                      <li>{"Peer code review and support."}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="timeline">
              <div className="timeline-content">
                <span className="bullets" />
                <div className="title">
                  <h3>Sr. Programmer</h3>
                  <h6 className="card-subtitle text-muted">
                    <div className="company-name">Biz4Solutions, Pune</div>
                    <div className="service-duration">
                      April 2018 - Oct 2016
                    </div>
                  </h6>
                </div>

                <div className="card description">
                  <div className="card-body">
                    <ul className="card-text">
                      <li>
                        <strong>Technologies Worked:</strong> Angular(5/4/2),
                        Ionic Framework, TypeScript, JavaScript, HTML5, CSS,
                        Bootstrap.
                      </li>
                      <li>
                        {"Building web applications components using Angular."}
                      </li>
                      <li>
                        {
                          "Building mobile applications using an Ionic Framework."
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ProfessionalExperience;
