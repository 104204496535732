import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

const HeadBar = () => {
    // Collapse isOpen State
    const [isOpen, setIsOpen] = React.useState(false);

    const scroll = (id) => {
      var element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    }
  
    return (
        <>
          <Navbar color="dark" dark expand="sm" style={{ paddingLeft: "20px" }}>
            <NavbarBrand href="/">Avinash Rathod</NavbarBrand>
            <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="me-auto" navbar>
                <NavItem>
                  <NavLink
                    href="#"
                    active
                    onClick={() => scroll("home-tab")}
                  >
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" onClick={() => scroll("intro-tab")}>
                    About
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#" onClick={() => scroll("skill-tab")}>
                    Skills
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => scroll("services-tab")}
                  >
                    Services
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() => scroll("contact-tab")}
                  >
                    Contact
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </>
    );
}

export default HeadBar;
