import React from "react";
import { Row, Col } from "reactstrap";

const Services = () => (
  <div id="services-tab" className="section-container">
    <div className="section-header">
      <h2>Services</h2>
    </div>
    <div className="container-fluid section services-section">
      <Row>
        <Col lg="4" md="6" sm="12">
          <div className="service-item">
            <span className="service-icon">
              <i className="fas fa-laptop-code" />
            </span>
            <h3>Front-end Development</h3>
            <p>
              We use the latest front-end development tools, technologies (like
              React, Angular etc) and industry best practices for developing
              useful web applications.
            </p>
          </div>
        </Col>
        <Col lg="4" md="6" sm="12">
          <div className="service-item">
            <span className="service-icon">
              <i className="fas fa-mobile-alt" />
            </span>
            <h3>Mobile App Development</h3>
            <p>
              Expertise in hybrid mobile app development with ionic framework
              that seamlessly work across devices or platforms.
            </p>
          </div>
        </Col>
        <Col lg="4" md="6" sm="12">
          <div className="service-item">
            <span className="service-icon">
              <i className="fas fa-desktop" />
            </span>
            <h3>Responsive Design</h3>
            <p>
              We design web application with "Responsive Web Design" approach
              that makes web pages render well on a variety of screen sizes.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default Services;
